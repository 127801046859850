import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthHead from '../components/AuthHead';
import ErrorMessage from '../components/ErrorMessage';
import { request } from '../services/utilities';
import waiting from '../assets/images/waiting.gif';
import authImage from '../assets/images/image_04.png';
import { notifyError, notifySuccess } from '../services/notify';
import ErrorAlert from '../components/ErrorAlert';
import { useAuth } from '../hooks/auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { decode } from 'jsonwebtoken';

const Login = () => {
	const [submitting, setSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState('');

	const { setAuthUser } = useAuth();
	const history = useHistory();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Required'),
			password: Yup.string().required('Required'),
		}),
		onSubmit: async values => {
			try {
				setSubmitError('');
				setSubmitting(true);
				const config = { method: 'POST', body: values };
				const rs = await request('auth/login', config);
				setSubmitting(false);
				if (rs.success) {
					setAuthUser(rs.data);
					notifySuccess('login successful!');
					history.replace('/');
				} else {
					setSubmitError(rs.message || 'login failed');
				}
			} catch (e) {
				console.log(e);
				setSubmitting(false);
				setSubmitError(e.message || 'login failed');
			}
		},
	});

	const autoLogin = async values => {
		try {
			setSubmitError('');
			setSubmitting(true);
			const { email, password } = values;
			const config = { method: 'POST', body: { email, password } };
			const rs = await request('auth/login', config);
			setSubmitting(false);
			if (rs.success) {
				setAuthUser(rs.data);
				history.replace('/profile/patient#edit-profile');
			} else {
				setSubmitError(rs.message || 'login failed');
			}
		} catch (e) {
			console.log(e);
			setSubmitting(false);
			setSubmitError(e.message || 'login failed');
		}
	};

	const decodeToken = ({ token }) => {
		try {
			const decoded = decode(token, { complete: true });
			return decoded;
		} catch (error) {
			notifyError('Error decoding: ', error.message);
			console.error('Error decoding:', error.message);
			return null;
		}
	};

	const tokenNotExpired = decoded => {
		if (decoded && decoded.exp) {
			const currentTime = Math.floor(Date.now() / 1000);
			if (decoded.exp < currentTime) {
				notifyError('Token has expired');
				return false;
			} else {
				return true;
			}
		} else {
			notifyError('Token does not have an expiration time');
			return false;
		}
	};

	useEffect(() => {
		const token = queryParams.get('token');
		if (token && token !== '') {
			setSubmitting(true);
			const decoded = decodeToken({ token });
			if (
				decoded
				// && tokenNotExpired(decoded)
			) {
				autoLogin(decoded.payload);
			} else {
				setSubmitting(false);
				setSubmitError('Auto-login failed: please login manually to update profile!');
			}
		}
	}, []);

	return (
		<>
			<AuthHead />
			<div id="content-body">
				<div className="py-5 text-center w-100">
					<div className="row mx-auto auth-block">
						<div className="col auth-image">
							<img src={authImage} alt="" />
						</div>
						<div className="separator w-32" />
						<div className="col-sm-10 w-xl unset-flex login">
							<form name="form" onSubmit={formik.handleSubmit}>
								{submitError !== '' && <ErrorAlert error={submitError} />}
								<div className="form-group">
									<input
										name="email"
										className={`form-control ${
											formik.touched.username && formik.errors.username ? 'is-invalid' : ''
										}`}
										placeholder="Email/ Username"
										type="text"
										{...formik.getFieldProps('email')}
									/>
									{formik.touched.username && formik.errors.username && (
										<ErrorMessage error={formik.errors.username} />
									)}
								</div>
								<div className="form-group">
									<input
										name="password"
										className={`form-control ${
											formik.touched.password && formik.errors.password ? 'is-invalid' : ''
										}`}
										placeholder="Password"
										type="password"
										{...formik.getFieldProps('password')}
									/>
									{formik.touched.password && formik.errors.password && (
										<ErrorMessage error={formik.errors.password} />
									)}
								</div>
								<button type="submit" className="btn primary" disabled={submitting}>
									{submitting ? <img alt="" src={waiting} /> : 'Sign In'}
								</button>
							</form>
							<div className="my-4">
								<Link to="/forgot-password" className="text-primary _600">
									Forgot password?
								</Link>
							</div>
							<div>
								Do not have an account?{' '}
								<Link to="/register" className="text-primary _600">
									Sign up
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
