import React from 'react';

const ErrorMessage = ({ error }) => {
	return (
		<ul className="list-unstyled text-sm mt-1 mb-0 error-label">
			<li>{error}</li>
		</ul>
	);
};

export default ErrorMessage;
