import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo2.png';

const AuthHead = () => {
	return (
		<div className="navbar light bg pos-rlt box-shadow">
			<div className="mx-auto">
				<Link to="/" className="navbar-brand">
					<img src={logo} alt="." />
				</Link>
			</div>
		</div>
	);
};

export default AuthHead;
