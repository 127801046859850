import React from 'react';

const ErrorAlert = ({ title = '', error }) => {
	return (
		<div className="alert alert-danger">
			{title !== '' && <h6>{title}</h6>}
			{error}
		</div>
	);
};

export default ErrorAlert;
